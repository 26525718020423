import React from "react";
import { Glp } from "../helper/assets";
import { useNavigate } from "react-router";
import ClinicInfo from "./Blog/BlogDetail/Clinic";
import { blogFour } from "../helper/content";
import LiraglutideHeading from "./Blog/BlogDetail/LiraglutideHeading";

const BlogSection = ({ heading, paragraphs }) => (
  <>
    <h2 className="main-section-heading">{heading}</h2>
    {Array.isArray(paragraphs) ? (
      paragraphs.map((section, index) => (
        <div key={index} className="semagluted-margin">
          <h2 className="head-style">{section.heading}</h2>
          <p className="head-text">
            {Array.isArray(section.paragraph)
              ? section.paragraph.map((para, i) => <li key={i}>{para}</li>)
              : section.paragraph}
          </p>
        </div>
      ))
    ) : (
      <p className="head-text">{paragraphs}</p>
    )}
  </>
);

const LiraglutideBlog = () => {
  const {
    intro = [],
    sections = [],
    Semaglutide_overview = [],
    Liraglutide_overview = [],
    Semaglutide_Efficacy = [],
    Liraglutide_Efficacy = [],
    Side_Effect_Safety = [],
    Dosage = [],
    Patient_Experiences = [],
    Cost = [],
    Accessibility_Availability = [],
    Pros_Cons = [], 
    Future = [],
    Conclusion = [],
    FAQs = [],
  } = blogFour;

  const navigate = useNavigate();

  const handleBackBlogList = () => {
    navigate("/blog");
  };

  return (
    <div className="responsive-container">
      <div className="container-padding">
        <div className="discover-container">
          <LiraglutideHeading />
          <img src={Glp} alt="glp" className="blog-glp-img" />
        </div>

        {intro.map((item, index) => (
          <div key={index} className="semagluted-margin">
            <ClinicInfo title={item.title} desc={item.description} />
          </div>
        ))}

        <BlogSection
          heading="Understanding GLP-1 Receptor Agonists"
          paragraphs={sections}
        />
        <BlogSection
          heading="Semaglutide Overview"
          paragraphs={Semaglutide_overview}
        />
        <BlogSection
          heading="Liraglutide Overview"
          paragraphs={Liraglutide_overview}
        />
        <BlogSection
          heading="Efficacy of Semaglutide"
          paragraphs={Semaglutide_Efficacy}
        />
        <BlogSection
          heading="Efficacy of Liraglutide"
          paragraphs={Liraglutide_Efficacy}
        />
        <BlogSection
          heading="Side Effects and Safety"
          paragraphs={Side_Effect_Safety}
        />
        <BlogSection heading="Dosage and Administration" paragraphs={Dosage} />
        <BlogSection
          heading="Patient Experiences and Reviews"
          paragraphs={Patient_Experiences}
        />
        <BlogSection heading="Cost Comparison" paragraphs={Cost} />
        <BlogSection
          heading="Accessibility and Availability"
          paragraphs={Accessibility_Availability}
        />
        <BlogSection heading="Pros and Cons" paragraphs={Pros_Cons} />
        <BlogSection heading="Future Developments" paragraphs={Future} />
        <BlogSection heading="Conclusion" paragraphs={Conclusion} />
        <BlogSection heading="FAQs" paragraphs={FAQs} />

        <div
          onClick={handleBackBlogList}
          className="blog-button padding-from-bottom"
        >
          Back to Blog List
        </div>
      </div>
    </div>
  );
};

export default LiraglutideBlog;
