import React from "react";
import "./Blog-four.css";

const LiraglutideHeading = () => {
  return (
    <div>
      <div className="heading-style heading-none-text">
        <span className="heading-text-style">Semaglutide Vs Liraglutide:</span><br />
        <span className="heading-text-style">A Comprehensive Comparison</span>
      </div>
      <div className="blog-author-container">
        <div className="author-title">By OTC-BODY</div>
        <div className="author-title">July 20, 2024</div>
      </div>
    </div>
    
  );
};
export default LiraglutideHeading;
