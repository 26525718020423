import React, { useState } from 'react';
import CommonLayout from '../CommonLayout';
import { FAQAnswer, Question, QuestionCheckMark } from '../../helper/assets';
import './Faq.css';
import { faqQuestion } from '../../helper/content';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <CommonLayout>
      <div className='faq-container'>
        <div className='faq-header'>
          <div className='faq-title'>
            <h1>FREQUENTLY ASKED QUESTIONS</h1>
          </div>
          <img
            src={Question}
            alt='FAQ Questions'
            className='faq-question-image'
          />
        </div>
        <div className='faq-content'>
          <div className='faq-main'>
            {faqQuestion.map((item, index) => (
              <div
                key={index}
                className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAnswer(index)}
              >
                <div className='faq-question-header'>
                  <img
                    src={QuestionCheckMark}
                    alt='Check Mark'
                    className='check-mark'
                  />
                  <div className='faq-question'>{item.question}</div>
                </div>
                {activeIndex === index && (
                  <div className='faq-answer-card'>
                    <div className='faq-answer'>{item.answer}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Faq;
