import React from "react";
import "../ConnectTeam.css";
import "./DocInformation.css";
import AppointmentRequestWidget from "../../Appoinments/Appoinment";

const DocInformation = ({
  docImg,
  name,
  post,
  desc,
  initialScopeId,
  initialScopeUrl,
  initialApplicationId,
  customUrl
}) => {
  return (
    <div className="doc-card-main">
      <img
        className="doc-card-img"
        src={docImg}
        alt={name}
        height="260px"
        width="260px"
        loading="lazy"
      />
      <div className="doc-card-name">{name}</div>
      <div className="doc-card-post">{post}</div>
      <div className="appoint-req-btn doc-card-post">
        <AppointmentRequestWidget
          initialScopeId={initialScopeId}
          initialScopeUrl={initialScopeUrl}
          initialApplicationId={initialApplicationId}
          desc={desc}
          customUrl={customUrl} 
        />
      </div>
    </div>
  );
};

export default DocInformation;
